<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface FormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
}
    import { onClickOutside } from '@vueuse/core'
    import Str from '@supercharge/strings'
    import { FormInputData } from '~~/composables/useFormInput'
    const { $theme } = useNuxtApp();
    const { tenant } = useAuthStore();
    const props = defineProps<FormInputProps>();
    const inputData: FormInputData = useFormInput(props);
    const focused = ref(false);
    const emailInput = ref(null);
    onClickOutside(emailInput, (event) => (focused.value = false));
    const isValid = computed(() => {
        let regex = /\S+@\S+\.\S+/;
        return regex.test(inputData.model.value);
    });
    const suggestions = computed<string[]>(() => {
        if (!focused.value || !inputData.model.value || inputData.model.value.length < 3) {
            return [];
        }
        const providers = ['gmail.com', 'outlook.com', 'hotmail.com', 'hotmail.fr', 'orange.fr', 'yahoo.com', 'yahoo.fr', 'live.com'];
        // TODO: Customize the providers depending on the tenant's country.
        // if ( tenant.billingCountry == 'fr' ) {
        //     providers.unshift('free.fr');
        // }
        const all = providers.map((provider) => Str(inputData.model.value).before('@').append(`@${provider}`).get());
        return all.filter((email) => email.startsWith(inputData.model.value) && email !== inputData.model.value);
    });
</script>

<template>
    <FormElement :inputData="inputData">
        <div
            class="relative"
            ref="emailInput"
        >
            <input
                v-model="inputData.model.value"
                :class="[inputData.className.value, { 'border-red-500': !isValid && inputData.model.value.length > 0 }]"
                :name="inputData.props.inputName"
                :id="inputData.inputId.value"
                :placeholder="inputData.placeholder.value"
                type="email"
                :disabled="inputData.props.disabled"
                @focus="focused = true"
            />
            <div
                :class="$theme('submenu.container.default', 'w-full')"
                v-if="suggestions.length > 0"
            >
                <div :class="$theme('submenu.items')">
                    <a
                        href="#"
                        v-for="suggestion in suggestions"
                        :key="suggestion"
                        :class="$theme('submenu.item')"
                        @click.prevent="inputData.model.value = suggestion"
                    >
                        {{ suggestion }}
                    </a>
                </div>
            </div>
        </div>
    </FormElement>
</template>
